<template>
  <v-sheet elevation="2" class="pa-5">
    <v-container fluid>
      <v-row class="mb-3">
        <v-col lg="12" md="12" sm="12">
          <h2>Select Tags</h2>
        </v-col>
      </v-row>
      <!--Tags-->
      <v-row>
        <v-col lg="12" md="12" sm="12">
          <v-chip-group v-model="selected_tags" column multiple>
            <v-chip
              v-for="tag in tags"
              :key="tag.name"
              class="mr-2 mb-3"
              filter
              active-class="success"
              @click="addTagToSelectedGroup(tag)"
            >
              <v-icon left small>
                {{ tag.icon }}
              </v-icon>
              {{ tag.name }}
            </v-chip>
          </v-chip-group>
        </v-col>
      </v-row>
    </v-container>
  </v-sheet>
</template>
<script>
import db from "../../../components/firebaseInit";
import mixin_tags from "../mixins/mixin_tags";
export default {
  name: "TransactionTagDialog",
  props: ["item"],
  mixins: [mixin_tags],
  data() {
    return {
      selected_tags: this.selected_tags,
    };
  },
  created() {},
  methods: {
    // TAGS
    //_______________________________
    addTagToSelectedGroup(tag) {
      // change selected tag toggle value
      tag.toggled = !tag.toggled;
      this.selected_tags = this.tags.filter((tag) => tag.toggled === true);
      const transactionRef = db
        .collection("bank_transactions")
        .doc(this.item.transaction_id);
      transactionRef
        .update({
          selected_tags: this.selected_tags,
        })
        .then(() => {
          console.log("Tags successfully updated!");
        })
        .catch((error) => {
          // The document probably doesn't exist.
          console.error("Error updating document: ", error);
        });
    },
  },
};
</script>
